/*******************************************************/
/* ---- IMPORTANT! Read before making any changes ---- */
/*******************************************************/
/* ----    This file is part of a set of files    ---- */
/* ----    Any changes here MUST be added to:     ---- */
/*******************************************************/
/* - o365.pwa.declaration.shared.IndexedDBHandler.d.ts */
/* - o365.pwa.modules.client.IndexedDBHandler.ts       */
/* - o365.pwa.modules.sw.IndexedDBHandler.ts           */
/*******************************************************/

import type { App } from 'o365.pwa.declaration.shared.dexie.objectStores.App.d.ts';
import type { Database } from 'o365.pwa.declaration.shared.dexie.objectStores.Database.d.ts';
import type { ObjectStore } from 'o365.pwa.declaration.shared.dexie.objectStores.ObjectStore.d.ts';
import type { Index } from 'o365.pwa.declaration.shared.dexie.objectStores.Index.d.ts';
import type { PWAState } from 'o365.pwa.declaration.shared.dexie.objectStores.PWAState.d.ts';
import type { ServiceWorkerState, IServiceWorkerStateOptions } from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerState.d.ts';
import type { ServiceWorkerScriptState } from 'o365.pwa.declaration.shared.dexie.objectStores.ServiceWorkerScriptState.d.ts';
import type { AppResourceState } from 'o365.pwa.declaration.shared.dexie.objectStores.AppResourceState.d.ts';
import type { User } from 'o365.pwa.declaration.shared.dexie.objectStores.User.d.ts';
import type { GlobalSetting } from 'o365.pwa.declaration.shared.dexie.objectStores.GlobalSetting.d.ts';
import type { IServiceWorkerImportMapEntry } from 'o365.pwa.declaration.sw.IServiceWorkerImportmap.d.ts';
import type { UserDevice, IUserDeviceOptions } from 'o365.pwa.declaration.shared.dexie.objectStores.UserDevice.d.ts';
import type { FileStoreRecord, IFileStoreRecordOptions } from 'o365.pwa.declaration.shared.dexie.objectStores.FileStoreFile.d.ts';
import type { Table } from 'o365.pwa.declaration.sw.dexie.d.ts';

declare module 'o365.pwa.declaration.shared.IndexedDBHandler.d.ts' {
    export interface IIndexedDBCache {
        readonly apps: Map<string, IAppCache>;
        user: User | null;
        globalSetting: GlobalSetting | null;
    }

    export interface IAppCache {
        value: App;
        databases: Map<string, IDatabaseCache>;
        pwaState: PWAState | null;
        serviceWorkerState: IServiceWorkerStateCache | null;
    }

    export interface IDatabaseCache {
        value: Database;
        objectStores: Map<string, IObjectStoreCache>;
    }

    export interface IObjectStoreCache {
        value: ObjectStore;
        indexes: Map<string, IIndexCache>;
    }

    export interface IIndexCache {
        value: Index;
    }

    export interface IServiceWorkerStateCache {
        value: ServiceWorkerState;
        serviceWorkerScriptStates: Map<string, IServiceWorkerScriptStateCache>;
        appResourceStates: Map<string, IAppResourceStateCache>;
    }

    export interface IServiceWorkerScriptStateCache {
        value: ServiceWorkerScriptState;
    }

    export interface IAppResourceStateCache {
        value: AppResourceState;
    }

    export interface IDataObjectDexieValues {
        appId: string;
        databaseId: string;
        objectStoreId: string;
    }

    export interface IndexedDBAppHandler {
        getApps: () => Promise<Array<App>>;
        getApp: (appId: string) => Promise<App | null>;
        getAppCache: (appId: string) => Promise<IAppCache | null>;
        getAppFromCache: (appId: string) => Promise<App | null>;
        getAppFromIndexedDB: (appId: string) => Promise<App | null>;
        createApp: (appId: string, title?: string, icon?: string) => Promise<App>;
        updateApp: (app: App) => Promise<void>;
        deleteApp: (app: App) => Promise<void>;
        addAppsToCache: (apps: Array<App>) => void;
        addAppToCache: (app: App) => void;
        removeAppFromCache: (app: App) => void;
        ifExistsRemoveAppFromCache: (appId: string) => void;
    }

    export interface IndexedDBDatabaseHandler {
        getDatabases: (appId: string) => Promise<Array<Database>>;
        getDatabase: (appId: string, databaseId: string) => Promise<Database | null>;
        getDatabaseCache: (appId: string, databaseId: string) => Promise<IDatabaseCache | null>;
        getDatabaseFromCache: (appId: string, databaseId: string) => Promise<Database | null>;
        getDatabaseFromIndexedDB: (appId: string, databaseId: string) => Promise<Database | null>;
        createDatabase: (appId: string, databaseId: string) => Promise<Database>;
        updateDatabase: (database: Database) => Promise<void>;
        deleteDatabase: (database: Database) => Promise<void>;
        addDatabasesToCache: (databases: Array<Database>) => Promise<void>;
        addDatabaseToCache: (database: Database) => Promise<void>;
        removeDatabaseFromCache: (database: Database) => Promise<void>;
        ifExistsRemoveDatabaseFromCache: (appId: string, databaseId: string) => Promise<void>;
    }

    export interface IndexedDBObjectStoreHandler {
        getObjectStores: (appId: string, databaseId: string) => Promise<Array<ObjectStore>>;
        getObjectStore: (appId: string, databaseId: string, objectStoreId: string) => Promise<ObjectStore | null>;
        getObjectStoreCache: (appId: string, databaseId: string, objectStoreId: string) => Promise<IObjectStoreCache | null>;
        getObjectStoreFromCache: (appId: string, databaseId: string, objectStoreId: string) => Promise<ObjectStore | null>;
        getObjectStoreFromIndexedDB: (appId: string, databaseId: string, objectStoreId: string) => Promise<ObjectStore | null>;
        createObjectStore: (appId: string, databaseId: string, objectStoreId: string, jsonDataVersion: number | null, fields: Array<string> | null) => Promise<ObjectStore>;
        updateObjectStore: (objectStore: ObjectStore) => Promise<void>;
        deleteObjectStore: (objectStore: ObjectStore) => Promise<void>;
        addObjectStoresToCache: (objectStores: Array<ObjectStore>) => Promise<void>;
        addObjectStoreToCache: (objectStore: ObjectStore) => Promise<void>;
        removeObjectStoreFromCache: (objectStore: ObjectStore) => Promise<void>;
        ifExistsRemoveObjectStoreFromCache: (appId: string, databaseId: string, objectStoreId: string) => Promise<void>;
    }

    export interface IndexedDBIndexHandler {
        getIndexes: (appId: string, databaseId: string, objectStoreId: string) => Promise<Array<Index>>;
        getIndex: (appId: string, databaseId: string, objectStoreId: string, indexId: string) => Promise<Index | null>;
        getIndexCache: (appId: string, databaseId: string, objectStoreId: string, indexId: string) => Promise<IIndexCache | null>;
        getIndexFromCache: (appId: string, databaseId: string, objectStoreId: string, indexId: string) => Promise<Index | null>;
        getIndexFromIndexedDB: (appId: string, databaseId: string, objectStoreId: string, indexId: string) => Promise<Index | null>;
        createIndex: (appId: string, databaseId: string, objectStoreId: string, indexId: string, keyPath: string | Array<string> | null, isPrimaryKey?: boolean, isUnique?: boolean, isMultiEntry?: boolean, isAutoIncrement?: boolean) => Promise<Index>;
        updateIndex: (index: Index) => Promise<void>;
        deleteIndex: (index: Index) => Promise<void>;
        addIndexesToCache: (indexes: Array<Index>) => Promise<void>;
        addIndexToCache: (index: Index) => Promise<void>;
        removeIndexFromCache: (index: Index) => Promise<void>;
        ifExistsRemoveIndexFromCache: (appId: string, databaseId: string, objectStoreId: string, indexId: string) => Promise<void>;
    }

    export interface IndexedDBPWAStateHandler {
        getPWAState: (appId: string) => Promise<PWAState | null>;
        getPWAStateFromCache: (appId: string) => Promise<PWAState | null>;
        getPWAStateFromIndexedDB: (appId: string) => Promise<PWAState | null>;
        createPWAState: (appId: string, appState: string, hasDatabaseConnection: boolean) => Promise<PWAState>;
        updatePWAState: (pwaState: PWAState) => Promise<void>;
        deletePWAState: (pwaState: PWAState) => Promise<void>;
        addPWAStateToCache: (pwaState: PWAState) => Promise<void>;
        removePWAStateFromCache: (pwaState: PWAState) => Promise<void>;
        ifExistsRemovePWAStateFromCache: (appId: string) => Promise<void>;
    }

    export interface IndexedDBServiceWorkerStateHandler {
        getServiceWorkerState: (appId: string) => Promise<ServiceWorkerState | null>;
        getServiceWorkerStateCache: (appId: string) => Promise<IServiceWorkerStateCache | null>;
        getServiceWorkerStateFromCache: (appId: string) => Promise<ServiceWorkerState | null>;
        getServiceWorkerStateFromIndexedDB: (appId: string) => Promise<ServiceWorkerState | null>;
        createServiceWorkerState: (options: IServiceWorkerStateOptions) => Promise<ServiceWorkerState>;
        updateServiceWorkerState: (serviceWorkerState: ServiceWorkerState) => Promise<void>;
        deleteServiceWorkerState: (serviceWorkerState: ServiceWorkerState) => Promise<void>;
        addServiceWorkerStateToCache: (serviceWorkerState: ServiceWorkerState) => Promise<void>;
        removeServiceWorkerStateFromCache: (serviceWorkerState: ServiceWorkerState) => Promise<void>;
        ifExistsRemoveServiceWorkerStateFromCache: (appId: string) => Promise<void>;
        clearServiceWorkerStateCache: () => void;
    }

    export interface IndexedDBServiceWorkerScriptStateHandler {
        getServiceWorkerScriptStates: (appId: string) => Promise<Array<ServiceWorkerScriptState>>;
        getServiceWorkerScriptState: (appId: string, serviceWorkerScriptStateId: string) => Promise<ServiceWorkerScriptState | null>;
        getServiceWorkerScriptStateCache: (appId: string, serviceWorkerScriptStateId: string) => Promise<IServiceWorkerScriptStateCache | null>;
        getServiceWorkerScriptStateFromCache: (appId: string, serviceWorkerScriptStateId: string) => Promise<ServiceWorkerScriptState | null>;
        getServiceWorkerScriptStateFromIndexedDB: (appId: string, serviceWorkerScriptStateId: string) => Promise<ServiceWorkerScriptState | null>;
        createServiceWorkerScriptState: (appId: string, serviceWorkerScriptStateId: string, importmapEntry: IServiceWorkerImportMapEntry) => Promise<ServiceWorkerScriptState>;
        updateServiceWorkerScriptState: (serviceWorkerScriptState: ServiceWorkerScriptState) => Promise<void>;
        deleteServiceWorkerScriptState: (serviceWorkerScriptState: ServiceWorkerScriptState) => Promise<void>;
        addServiceWorkerScriptStatesToCache: (serviceWorkerScriptStates: Array<ServiceWorkerScriptState>) => Promise<void>;
        addServiceWorkerScriptStateToCache: (serviceWorkerScriptState: ServiceWorkerScriptState) => Promise<void>;
        removeServiceWorkerScriptStateFromCache: (serviceWorkerScriptState: ServiceWorkerScriptState) => Promise<void>;
        ifExistsRemoveServiceWorkerScriptStateFromCache: (appId: string, serviceWorkerScriptStateId: string) => Promise<void>;
    }

    export interface IndexedDBAppResourceStateHandler {
        getAppResourceStates: (appId?: string) => Promise<Array<AppResourceState>>;
        getAppResourceState: (appId: string, appResourceStateId: string) => Promise<AppResourceState | null>;
        getAppResourceStateCache: (appId: string, appResourceStateId: string) => Promise<IAppResourceStateCache | null>;
        getAppResourceStateFromCache: (appId: string, appResourceStateId: string) => Promise<AppResourceState | null>;
        getAppResourceStateFromIndexedDB: (appId: string, appResourceStateId: string) => Promise<AppResourceState | null>;
        createAppResourceState: (appId: string, appResourceStateId: string, relativeRoots: Array<string>, urls: Array<string>, scopes: Array<string>) => Promise<AppResourceState>;
        updateAppResourceState: (appResourceState: AppResourceState) => Promise<void>;
        deleteAppResourceState: (appResourceState: AppResourceState) => Promise<void>;
        addAppResourceStatesToCache: (appResourceStates: Array<AppResourceState>) => Promise<void>;
        addAppResourceStateToCache: (appResourceState: AppResourceState) => Promise<void>;
        removeAppResourceStateFromCache: (appResourceState: AppResourceState) => Promise<void>;
        ifExistsRemoveAppResourceStateFromCache: (appId: string, appResourceStateId: string) => Promise<void>;
    }

    export interface IndexedDBUserHandler {
        getUser: () => Promise<User | null>;
        getUserFromCache: () => User | null;
        getUserFromIndexedDB: () => Promise<User | null>;
        createUser: (personId: number, userSession: any) => Promise<User>;
        updateUser: (user: User) => Promise<void>;
        deleteUser: (user: User) => Promise<void>;
        addUserToCache: (user: User) => void;
        removeUserFromCache: () => void;
        ifExistsRemoveUserFromCache: () => void;
    }

    export interface IndexedDBGlobalSettingHandler {
        getGlobalSetting: () => Promise<GlobalSetting | null>;
        getGlobalSettingFromCache: () => GlobalSetting | null;
        getGlobalSettingFromIndexedDB: () => Promise<GlobalSetting | null>;
        createGlobalSetting: (cdnUrl: string) => Promise<GlobalSetting>;
        updateGlobalSetting: (globalSetting: GlobalSetting) => Promise<void>;
        deleteGlobalSetting: (globalSetting: GlobalSetting) => Promise<void>;
        addGlobalSettingToCache: (globalSetting: GlobalSetting) => void;
        removeGlobalSettingFromCache: () => void;
        ifExistsRemoveGlobalSettingFromCache: () => void;
    }

    export interface IndexedDBDexieHandler {
        getDataObjectDexieValues: (url: string | URL, dataObjectId: string) => IDataObjectDexieValues;
        getDexieInstance: (appId: string, databaseId: string, objectStoreId: string) => Promise<Table | null>;
    }

    export interface IndexedDBFileStoreHandler {
        retrieveFileStoreRecord: (primKey: string) => Promise<FileStoreRecord | null>;
        retrieveFileStoreRecords: (primKeys: Array<string>) => Promise<Array<FileStoreRecord>>;
        createFileStoreRecord: (fileStoreRecordOptions: IFileStoreRecordOptions) => Promise<void>;
        bulkCreateFileStoreRecords: (fileStoreRecordsOptions: Array<FileStoreRecord>) => Promise<void>;
        updateFileStoreRecord: (fileStoreRecord: FileStoreRecord) => Promise<void>;
        bulkUpdateFileStoreRecords: (fileStoreRecords: Array<FileStoreRecord>) => Promise<void>;
        deleteFileStoreRecord: (fileStoreRecord: FileStoreRecord) => Promise<void>;
        bulkDeleteFileStoreRecords: (fileStoreRecords: Array<FileStoreRecord>) => Promise<void>;
    }

    export interface IndexedDBUserDeviceHandler {
        getUserDevice: () => Promise<UserDevice | null>;
        createUserDevice: (userDeviceOptions: IUserDeviceOptions) => Promise<void>;
        updateUserDevice: (userDevice: UserDevice) => Promise<void>;
        deleteUserDevice: (userDevice: UserDevice) => Promise<void>;
    }

    export type IndexedDBHandler = IndexedDBAppHandler &
        IndexedDBDatabaseHandler &
        IndexedDBObjectStoreHandler &
        IndexedDBIndexHandler &
        IndexedDBPWAStateHandler &
        IndexedDBServiceWorkerStateHandler &
        IndexedDBServiceWorkerScriptStateHandler &
        IndexedDBAppResourceStateHandler &
        IndexedDBUserHandler &
        IndexedDBGlobalSettingHandler &
        IndexedDBDexieHandler &
        IndexedDBFileStoreHandler &
        IndexedDBUserDeviceHandler;

    export const IndexedDBHandler: IndexedDBHandler;
}
